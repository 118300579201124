import { api } from '@soc/kit/api/index';

import { SoftwareRowType } from '../../shared/types';

export const abortControllerKey = 'soc/software';

type GetSoftwareEndpointType = SoftwareRowType[];

export const getSoftwareTab = (
  endpoint: string,
  uuid?: string,
): Promise<SoftwareRowType[]> => {
  return api<GetSoftwareEndpointType>(`${endpoint}${uuid}/software/`, {
    method: 'get',
    isMainInstallation: true,
    abortControllerKey,
  }).then((res) => res);
};
