import React from 'react';

import { useTranslate } from 'combinezone/utils/index';

import { ColumnType } from '../types';

import locales from '../../locales';

export const useColumnDeveloper = (): ColumnType => {
  const t = useTranslate(locales);
  return {
    Header: t('developer'),
    accessor: 'vendor.name',
    width: 200,
  };
};
