import React, { FC, memo, useMemo } from 'react';

import styled from 'styled-components';

import Body from '@soc/alerts/src/components/SidePanel/Panel/Content/Body';
import { Flex, Heading } from 'combinezone/core/index';
import { useTranslate } from 'combinezone/utils/index';

import locales from '../locales';

const HeaderContainer = styled(Flex).attrs(() => ({
  fillWidth: true,
}))`
  min-height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.basis.colors.borders.divider};
  padding: 0 24px;
`;

const ContentContainer = styled(Flex).attrs(() => ({
  fillHeight: true,
  direction: 'column',
}))`
  width: calc(100% - 57px);
  border-left: 1px solid ${({ theme }) => theme.basis.colors.borders.divider};
`;

export type ContentProps = {
  content: null | JSX.Element;
  testId: string;
  title?: string;
};

const SoftwareContent: FC<ContentProps> = ({ content, testId, title }) => {
  const t = useTranslate(locales);

  const header = useMemo(() => {
    if (!title) {
      return null;
    }

    return (
      <Flex alignItems="center" fillHeight fillWidth gap="8px">
        <Heading size="lg" isClipped variant="default">
          {title}
        </Heading>
      </Flex>
    );
  }, [title]);

  return (
    <ContentContainer testId={`${testId}-content-container-`}>
      <HeaderContainer testId={`${testId}-header-container-`}>
        {header || (
          <Flex fillHeight justify="center" alignItems="center">
            <Heading size="lg" isClipped variant="placeholder">
              {t('noData')}
            </Heading>
          </Flex>
        )}
      </HeaderContainer>{' '}
      <Body content={content} />
    </ContentContainer>
  );
};

export default memo(SoftwareContent);
