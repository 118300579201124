import React, { FC, useCallback, useRef, useState } from 'react';

import styled from 'styled-components';

import { NavigationMapKeysType } from '@soc/alerts/src/components/SidePanel/Panel/hooks';
import { Flex } from 'combinezone/core';
import { FlexProps } from 'combinezone/core/Flex/Flex';
import { DotResizer } from 'combinezone/icons';

import './Panel.scss';

import { useResize } from '../hooks/useResize';

import SoftwareContent from './Content';
import SoftwareNavigation from './Navigation';

const PanelBody = styled(Flex).attrs(() => ({
  fillWidth: true,
  grow: '1',
}))`
  height: 0;
`;

const ResizeMarker = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  width: 10px;
  cursor: ew-resize;
  background-color: ${({ theme }) => theme.basis.colors.base.desk};
`;

const PanelContainer = styled(Flex)<
  FlexProps & {
    width: number;
    isCollapsed: boolean;
  }
>`
  background-color: ${({ theme }) => theme.basis.colors.base.block};
  position: relative;
  width: ${({ isCollapsed, width }) =>
    isCollapsed ? '56px !important' : `${width}px`};
  min-width: ${({ isCollapsed }) =>
    isCollapsed ? '56px !important' : `450px`};
  z-index: 1;
  height: auto;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.basis.colors.shadows.default} 0px 0px 16px;
`;

type SoftwarePaneProps = {
  tabs: {
    key: NavigationMapKeysType;
    content: JSX.Element;
  }[];
  testId: string;
  title: string | undefined;
};

const changeSize = (
  resizableContainerElement: HTMLDivElement,
  width: number,
  offset: number,
  min: number,
  max: number,
): number => {
  const newValue = width - offset;
  if (newValue > min && newValue < max) {
    resizableContainerElement.style.width = `${newValue}px`;
    return newValue;
  }
  return width;
};

export const SoftwarePanel: FC<SoftwarePaneProps> = ({
  tabs,
  testId,
  title,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const panelRef = useRef(null);
  const resizeMakerRef = useRef<HTMLDivElement>(null);

  const { width } = useResize({
    resizableContainerRef: panelRef,
    draggableElementRef: resizeMakerRef,
    changeSize,
  });

  const togglePanel = useCallback(() => {
    setIsCollapsed((pre) => !pre);
  }, []);

  const content = tabs[activeTabIndex]?.content || null;

  return (
    <PanelContainer
      isCollapsed={isCollapsed}
      width={width}
      ref={panelRef}
      data-test-id={`${testId}-software-panel-container`}
    >
      <PanelBody>
        {!isCollapsed && (
          <ResizeMarker ref={resizeMakerRef}>
            <DotResizer testId={`${testId}-resize-marker`} />
          </ResizeMarker>
        )}
        <SoftwareNavigation
          isCollapsedPanel={isCollapsed}
          togglePanel={togglePanel}
          setActiveTabIndex={setActiveTabIndex}
          activeTabIndex={activeTabIndex}
          tabs={tabs}
          title={title}
          testId={`${testId}-software-panel-navigation`}
        />
        {!isCollapsed && (
          <SoftwareContent
            content={content}
            title={title}
            testId={`${testId}-software-panel-content`}
          />
        )}
      </PanelBody>
    </PanelContainer>
  );
};
