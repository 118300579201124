import { useCallback, useEffect, useState } from 'react';

import { abortApiFetch } from '@soc/kit/api';

import { fetchOidcProviders } from '../actions/fetchOidcProviders';
import { OidcProvidersFetchAbortControllerKey } from '../constants';
import { OidsProviderType, ProviderType } from '../types';

import { UseOidcProviders } from './types';

export const useOidcProviders: UseOidcProviders = () => {
  const [providers, setProviders] = useState<ProviderType[] | null>(null);
  const [isFetchingProviders, setIsFetchingProviders] = useState(false);

  const getOidsProviders = useCallback((): void => {
    setIsFetchingProviders(true);
    fetchOidcProviders()
      .then((res) => res.json())
      .then(async (res: { results: Array<OidsProviderType> }) => {
        setProviders(res.results);
      })
      .catch((err: Error) => {
        setProviders(null);
        return err;
      })
      .finally(() => {
        setIsFetchingProviders(false);
      });
  }, []);

  useEffect(() => {
    getOidsProviders();
    return () => {
      abortApiFetch(OidcProvidersFetchAbortControllerKey);
      setProviders(null);
    };
  }, []);

  return {
    providers,
    isFetchingProviders,
  };
};
