import React, { useEffect, useRef } from 'react';

export const sidePanelMinWidth = 450;

const changeSizeDefault = (
  resizableContainerElement,
  width,
  offset,
  min,
  max,
) => {
  const newValue = width + offset;
  if (newValue > min && newValue < max) {
    resizableContainerElement.style.width = newValue;
    return newValue;
  }
  return width;
};

const disableSelect = () => {
  return false;
};

export const useResize = ({
  changeSize = changeSizeDefault,
  defaultWidth = sidePanelMinWidth,
  draggableElementRef,
  max = 700,
  min = sidePanelMinWidth,
  resizableContainerRef,
} = {}) => {
  const widthRef = useRef(defaultWidth);
  const currentX = useRef(0);

  const handleMouseMove = (e) => {
    const offset = e.clientX - currentX.current;
    widthRef.current = changeSize(
      resizableContainerRef.current,
      widthRef.current,
      offset,
      min,
      max,
    );
    currentX.current += offset;
  };

  const handleMouseDown = (e) => {
    currentX.current = e.clientX;
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('selectstart', disableSelect);
    document.body.style['-webkit-user-select'] = 'none';
    document.body.style['-moz-user-select'] = '-moz-none';
    document.body.style['-ms-user-select'] = 'none';
    document.body.style['-user-select'] = 'none';
  };

  const handleMouseMouseUp = () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('selectstart', disableSelect);
    document.body.style['-webkit-user-select'] = 'inherit';
    document.body.style['-moz-user-select'] = 'inherit';
    document.body.style['-ms-user-select'] = 'inherit';
    document.body.style['-user-select'] = 'inherit';
  };

  useEffect(() => {
    const draggableElement = draggableElementRef.current;
    if (draggableElement) {
      draggableElement.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mouseup', handleMouseMouseUp);
    }
    return () => {
      if (draggableElement) {
        draggableElement.removeEventListener('mousedown', handleMouseMouseUp);
        window.removeEventListener('mouseup', handleMouseDown);
      }
    };
  }, [draggableElementRef.current]);

  return {
    width: widthRef.current,
    handleMouseMove,
    handleMouseDown,
    handleMouseMouseUp,
  };
};
