import React from 'react';

import { useTranslate } from 'combinezone/utils/index';

import { ColumnType } from '../types';

import locales from '../../locales';

type UseColumnVersionType = () => ColumnType;

export const useColumnVersion: UseColumnVersionType = () => {
  const t = useTranslate(locales);
  return {
    Header: t('version'),
    accessor: 'version',
    width: 130,
  };
};
