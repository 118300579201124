import React, { useMemo } from 'react';

import { GridColumn } from 'combinezone/datagrid/types';
import { useTranslate } from 'combinezone/utils/index';

import {
  useColumnTitle,
  useColumnVersion,
  useColumnDeveloper,
  useColumnDate,
} from './Column';
import locales from './locales';

export type ExpandedRowKeysType = {
  [k: string]: {
    isExpanded: boolean;
    source: string;
  };
};

export type UseDataGridColumnProps = {
  testId?: string;
};

type ColumnsType = (GridColumn<Record<string, unknown>> & {
  accessor: string;
})[];

const useDataGridColumns = ({
  testId = 'software-table',
}: UseDataGridColumnProps): ColumnsType => {
  const t = useTranslate(locales);

  const columnTitle = useColumnTitle();
  const columnInstallationDate = useColumnDate({
    headerText: t('installDate'),
    testId,
    accessor: 'install_time',
  });
  const columnDeveloper = useColumnDeveloper();
  const columnVersion = useColumnVersion();

  return useMemo((): ColumnsType => {
    return [
      columnTitle,
      columnDeveloper,
      columnInstallationDate,
      columnVersion,
    ].filter(Boolean) as ColumnsType;
  }, [t]);
};

export default useDataGridColumns;
