import React from 'react';

import { ColumnType } from '../types';

import Cell from './Cell';

type UseColumnDateType = ({
  accessor,
  headerText,
  testId,
}: {
  headerText: string;
  testId: string;
  accessor: string;
}) => ColumnType;

export const useColumnDate: UseColumnDateType = ({
  accessor,
  headerText,
  testId,
}) => {
  return {
    Header: headerText,
    accessor,
    width: 140,
    CellRenderer: ({
      cell: {
        row: {
          original: { group, isSubRow },
        },
        value,
      },
    }: {
      cell: any;
    }) => {
      if (!group || isSubRow) {
        return (
          <Cell
            testId={`${testId}-column-date-${accessor}-cell`}
            date={value}
          />
        );
      }
      return (
        <Cell
          testId={`${testId}-column-date-${accessor}-cell`}
          date={group[accessor]}
        />
      );
    },
  };
};
