import React, { FC } from 'react';

import { useOIDCAuth } from '@soc/kit/api';
import { Button } from 'combinezone/core';

import { ProviderType } from 'components/Login/types';

export const ProviderButton: FC<
  ProviderType & { testId: string; isLoading: boolean }
> = ({ client_id, configuration_url, isLoading, name, testId }) => {
  const { authOidc, isFetchingAuthOidc } = useOIDCAuth();

  return (
    <Button
      testId={`${testId}-button-provider`}
      variant="transparentWithBorder"
      isFullWidth
      isLoading={isLoading || isFetchingAuthOidc}
      onClick={() => {
        authOidc(configuration_url, client_id);
      }}
    >
      {name}
    </Button>
  );
};
