import React, { FC, memo, useState } from 'react';

import styled from 'styled-components';

import { Entity } from '@soc/alerts/src/components/SidePanel/types';
import { Flex, Block, BlockHeader } from 'combinezone/core/index';

import { Body } from './Body';
import { Panel } from './Panel';
import { useSoftwareTab } from './actions/software/hooks/useSoftwareTab';

type SoftwareProps = {
  testId: string;
  endpoint: string;
  uuid?: string;
};

export const LayoutContainer = styled(Flex).attrs(() => ({
  fillWidth: true,
  fillHeight: true,
}))`max-width: 1270px`;

const ContentContainer = styled(Flex)`
  overflow-y: hidden;
  position: relative;
  border-radius: ${({ theme }) => theme.basis.radius.base};
  border: 1px solid ${({ theme }) => theme.components.block.colors.border};
`;

export const BodyBox = styled(Flex).attrs(() => ({
  fillWidth: true,
  fillHeight: true,
  flex: 1,
  direction: 'column',
  alignItems: 'stretch',
  grow: 1,
}))`
  overflow: hidden;
  min-width: 400px;
`;

export const SoftwareTab: FC<SoftwareProps> = memo(
  ({ endpoint, testId, uuid }) => {
    const softwareTabData = useSoftwareTab(endpoint, uuid);
    const [pickedRow, setPickedRow] = useState<number | null>(null);

    return (
      <LayoutContainer>
        <BodyBox>
          <ContentContainer
            testId={`${testId}-list-block`}
            fillHeight
            direction="column"
          >
            <Body
              testId={`${testId}-software-table-body`}
              pickedRow={pickedRow}
              softwareTabData={softwareTabData}
              setPickedRow={setPickedRow}
            />
          </ContentContainer>
        </BodyBox>
        <Panel
          testId={`${testId}-software-panel-container`}
          pickedRow={pickedRow}
          softwareTabData={softwareTabData}
        />
      </LayoutContainer>
    );
  },
);
