import React, { useCallback, useEffect } from 'react';
import { Switch, withRouter } from 'react-router-dom';

import { useCheckOIDCAuth } from '@soc/kit/api/index';
import { ConfigProvider } from 'antd';
import enEn from 'antd/lib/locale-provider/en_US';
import ruRu from 'antd/lib/locale-provider/ru_RU';
import { OverlaySpinner, ToastProvider } from 'combinezone/core';
import { ThemeProvider } from 'combinezone/theme';
import { LanguageProvider } from 'combinezone/utils';

import { loadUserWithOIDC } from 'store/reducers/auth/actions';

import LoginContainer from '../../containers/LoginContainer';

import './App.scss';
import { useAppDispatch } from '../../hooks';
import { useInitTheme } from '../../utils/brending';
import Main from '../Main';

export default function AppComponent({
  auth: { isAuthenticated, user },
  loadUser,
  locale,
}) {
  const dispatch = useAppDispatch();
  const [socTheme, combinezoneTheme] = useInitTheme();

  useEffect(() => {
    if (isAuthenticated) {
      loadUser();
    }
  }, [isAuthenticated, loadUser]);

  const loginOIDC = useCallback(() => {
    dispatch(loadUserWithOIDC());
  }, []);

  useCheckOIDCAuth({
    signinRedirectCallback: loginOIDC,
  });

  return (
    <ThemeProvider theme={combinezoneTheme}>
      <ToastProvider>
        <ConfigProvider locale={locale === 'en' ? enEn : ruRu}>
          <LanguageProvider language={locale === 'en' ? 'en' : 'ru'}>
            <Switch>
              {!isAuthenticated ? (
                <LoginContainer />
              ) : user ? (
                <Main />
              ) : (
                <OverlaySpinner />
              )}
            </Switch>
          </LanguageProvider>
        </ConfigProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}

export const App = withRouter(AppComponent);
