import React, { FC, memo, Dispatch, SetStateAction } from 'react';

import styled from 'styled-components';

import ButtonTogglePanel from '@soc/alerts/src/components/SidePanel/Panel/ButtonTogglePanel';
import {
  NavigationMapKeysType,
  useNavigationMap,
} from '@soc/alerts/src/components/SidePanel/Panel/hooks';
import { Heading, Flex, IconButton } from 'combinezone/core';
import { useTranslate } from 'combinezone/utils/index';

import locales from '../locales';

const NavigationContainer = styled(Flex).attrs(() => ({
  fillHeight: true,
  direction: 'column',
  alignItems: 'center',
}))<{ isModal?: boolean }>`
  border-left: ${({ isModal, theme }) =>
    isModal ? 0 : `1px solid ${theme.basis.colors.borders.divider}`};
`;

const ContentContainer = styled(Flex).attrs(() => ({
  direction: 'column',
  fillWidth: true,
  fillHeight: true,
  alignItems: 'center',
  gap: '8px',
}))`
  padding: 8px 0;
  min-width: 56px;
`;

const TitleVertical = styled(Heading)`
  writing-mode: vertical-lr;
  height: calc(100% - 50px);
`;

export type NavigationTabType = {
  key: NavigationMapKeysType;
  isDisabled?: boolean;
};

type NavigationProps = {
  isCollapsedPanel: boolean;
  togglePanel: () => void;
  setActiveTabIndex: Dispatch<SetStateAction<number>>;
  activeTabIndex: number;
  tabs: NavigationTabType[];
  testId: string;
  title?: string;
};

const SoftwareNavigation: FC<NavigationProps> = ({
  activeTabIndex,
  isCollapsedPanel,
  setActiveTabIndex,
  tabs,
  testId,
  title,
  togglePanel,
}) => {
  const t = useTranslate(locales);
  const navigations = useNavigationMap();

  const content = (): JSX.Element | JSX.Element[] | null => {
    if (isCollapsedPanel) {
      return (
        <TitleVertical>
          <Heading
            size="lg"
            isClipped
            variant={title ? 'default' : 'placeholder'}
          >
            {title || t('noData')}
          </Heading>
        </TitleVertical>
      );
    }

    if (tabs.length) {
      return tabs.map(({ key }, index) => (
        <IconButton
          key={key}
          testId={`${testId}-navigation-${key}`}
          icon={navigations[key].icon}
          tooltip={navigations[key].title}
          onClick={() => {
            setActiveTabIndex(index);
          }}
          isPressed={index === activeTabIndex}
        />
      ));
    }

    return null;
  };

  return (
    <NavigationContainer>
      <ButtonTogglePanel
        testId={`${testId}-button-toggle-panel`}
        isCollapsedPanel={isCollapsedPanel}
        togglePanel={togglePanel}
      />
      <ContentContainer>{content()}</ContentContainer>
    </NavigationContainer>
  );
};

SoftwareNavigation.displayName = 'SoftwareNavigation';

export default memo(SoftwareNavigation);
