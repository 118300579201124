import React, { FC, memo } from 'react';

import { Text, Tooltip } from 'combinezone/core';
import { useLanguage } from 'combinezone/utils';

import { normalizeDate } from '../../../../helpers/normalizeDate';

type CellProps = {
  testId: string;
  date: string;
};

const Cell: FC<CellProps> = ({ date, testId }) => {
  const { language } = useLanguage();

  return (
    <Tooltip testId={`${testId}-tooltip`} position="top" content={date}>
      <Text testId={`${testId}-text`} isClipped>
        {date ? normalizeDate(date, language) : ''}
      </Text>
    </Tooltip>
  );
};

export default memo(Cell);
