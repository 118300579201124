import { useMemo } from 'react';

import { getAccessToken } from '@soc/kit/api';
import { getUser } from '@soc/kit/model';

export type useUserInfoFromTokenType = () => null | {
  id: number;
  username: string;
};

export const useUserInfoFromToken: useUserInfoFromTokenType = () => {
  return useMemo(() => {
    const token = getAccessToken();

    if (!token) {
      return null;
    }

    return getUser();
  }, []);
};
