import React, { FC } from 'react';

import styled from 'styled-components';

import { CMDB_ASSETS_ENDPOINT } from 'services/api';

import { SoftwareTab } from './SoftwareTab';

const TabContainer = styled.div`
  margin: 0 16px 16px 16px;
  height: 100%;
`;

const SoftwareTabWrapper: FC<{ assetKey: string }> = ({ assetKey }) => {
  return (
    <TabContainer>
      <SoftwareTab
        uuid={assetKey}
        testId="software-list-tab-asset-page"
        endpoint={CMDB_ASSETS_ENDPOINT}
      />
    </TabContainer>
  );
};

export default SoftwareTabWrapper;

SoftwareTabWrapper.displayName = 'SoftwareTabWrapper';
