import { useCallback, useEffect, useState } from 'react';

import { abortApiFetch } from '@soc/kit/api';

import { SoftwareRowType } from '../../../shared/types';
import { abortControllerKey, getSoftwareTab } from '../action';

type AbortError = Error & { name: 'AbortError' };

const isAbortError = (e: Error): e is AbortError => e.name === 'AbortError';

type UseSoftwareType = (
  endpoint: string,
  uuid?: string,
) => {
  data: SoftwareRowType[];
  isFetching: boolean;
  fetchData: () => Promise<unknown>;
};

export const useSoftwareTab: UseSoftwareType = (endpoint, uuid) => {
  const [data, setData] = useState<SoftwareRowType[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchData = useCallback(() => {
    setIsFetching(true);
    setData([]);
    return getSoftwareTab(endpoint, uuid)
      .then((res) => {
        setData(res);
        setIsFetching(false);
        return res;
      })
      .catch((err: Error) => {
        if (isAbortError(err)) {
          return err;
        }

        setIsFetching(false);
        return err;
      });
  }, []);

  useEffect(
    () => () => {
      abortApiFetch(abortControllerKey);
    },
    [],
  );

  return {
    data,
    isFetching,
    fetchData,
  };
};
