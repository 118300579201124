import React, {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import styled from 'styled-components';

import { useDataGridSettings } from '@common/soc-react-kit';
import { Flex } from 'combinezone/core/index';
import { BodyCell } from 'combinezone/datagrid/components/Cell/Cell';
import { RowStyles } from 'combinezone/datagrid/components/Row/Row';
import { Datagrid } from 'combinezone/datagrid/index';
import { GridRow } from 'combinezone/datagrid/types';

import {
  defaultHiddenColumnsSoftware,
  nonHiddenColumnsSoftware,
} from '../constants';
import useDataGridColumns from '../shared/DataGrid/useDataGridColumns';
import { SoftwareRowType } from '../shared/types';

const TableWrapper = styled(Flex).attrs(() => ({
  fillHeight: true,
}))<{
  selectedRowId: number | null;
}>`
  [data-test-id='${(props) => props.selectedRowId}'] {
    background-color: #d7edf6;
    color: #d7edf6;
  }
  flex-direction: column;
`;

const SoftwareDataGrid = styled(Datagrid<SoftwareRowType>)`
  ${RowStyles} {
    ${BodyCell}:nth-child(2) {
      position: sticky !important;
      left: 4px;
    }
  }
`;

export type ExpandedRowKeysType = {
  [k: string]: {
    isExpanded: boolean;
    source: string;
  };
};

export type SoftwareTabDataType = {
  data: SoftwareRowType[];
  isFetching: boolean;
  fetchData: () => Promise<unknown>;
};

export const Body: FC<{
  testId: string;
  pickedRow: number | null;
  setPickedRow: Dispatch<SetStateAction<number | null>>;
  softwareTabData: SoftwareTabDataType;
}> = memo(({ pickedRow, setPickedRow, softwareTabData, testId }) => {
  const { data, fetchData, isFetching } = softwareTabData;
  useEffect(() => {
    fetchData();
  }, []);

  const [selectedRow, setSelectedRow] = useState<Set<number | string>>(
    new Set(),
  );

  const columns = useDataGridColumns({
    testId: `${testId}-table`,
    isTab: true,
  });

  const {
    filteredColumns,
    injectInUseControlledState,
    saveColumnOrder,
    settingsButton,
  } = useDataGridSettings({
    initColumns: columns,
    dataGridName: `${testId}-software`,
    defaultHiddenColumns: defaultHiddenColumnsSoftware,
    nonHiddenColumns: nonHiddenColumnsSoftware,
  });

  const onRowClick = useCallback(
    ({ original: { id } }: GridRow<SoftwareRowType>) => {
      setPickedRow(id);
    },
    [],
  );

  const useControlledState = useCallback(
    (state: any) => {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      injectInUseControlledState(state as TableState<Record<string, unknown>>);

      state.selectedRowIds = Array.from(selectedRow).reduce<
        Record<string, any>
      >((selectedRows, id) => {
        selectedRows[id] = true;
        return selectedRows;
      }, {});
      // @ts-ignore
      return state;
    },
    [selectedRow],
  );

  return (
    <TableWrapper selectedRowId={pickedRow}>
      {settingsButton}
      <SoftwareDataGrid
        testId={`${testId}-data-grid`}
        data={data}
        // @ts-ignore
        columns={filteredColumns}
        isLoading={isFetching}
        autoResetSelectedRows={false}
        getRowId={({ id }) => `${id}`}
        onRowClick={onRowClick}
        canResizeColumn
        canChangeColumnOrder
        onChangeColumnOrder={saveColumnOrder}
        useControlledState={useControlledState}
        rowSelectMode="single"
      />
    </TableWrapper>
  );
});
