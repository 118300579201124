import React from 'react';

import { KeyValueRich } from '@common/soc-react-kit';

import './index.scss';

export function Section({ data, ...rest }) {
  return (
    <KeyValueRich>
      {data.map(
        ({
          alternativeLabel,
          key,
          label = '',
          text,
          value,
          vertical = false,
        }) => (
          <KeyValueRich.Item key={key}>
            <KeyValueRich.Key>
              {typeof label !== 'function' ? (
                <>
                  {alternativeLabel ? (
                    <>
                      <KeyValueRich.Title>{label}</KeyValueRich.Title>
                      <KeyValueRich.Caption>
                        {alternativeLabel}
                      </KeyValueRich.Caption>
                    </>
                  ) : (
                    label
                  )}
                </>
              ) : (
                <KeyValueRich.Name>{label()}</KeyValueRich.Name>
              )}
            </KeyValueRich.Key>
            {text ? (
              <KeyValueRich.Value text={text} />
            ) : (
              <KeyValueRich.Value>{value}</KeyValueRich.Value>
            )}
          </KeyValueRich.Item>
        ),
      )}
    </KeyValueRich>
  );
}
