import { isToday, format, isThisYear, parseISO } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';

export const normalizeDate = (
  ISOdate: string,
  locale: 'ru' | 'en' = 'ru',
): string => {
  const date = parseISO(ISOdate);
  const language = locale === 'ru' ? ruLocale : enLocale;

  if (isToday(date)) {
    return format(date, 'HH:mm:ss', {
      locale: language,
    });
  }
  if (isThisYear(date)) {
    return format(date, 'dd MMM, HH:mm:ss', {
      locale: language,
    });
  }
  return format(date, 'yyyy, dd MMM, HH:mm:ss', {
    locale: language,
  });
};
