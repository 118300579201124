import React from 'react';

import { useTranslate } from 'combinezone/utils/index';

import { ColumnType } from '../types';

import locales from '../../locales';

type UseColumnTitleType = () => ColumnType;

export const useColumnTitle: UseColumnTitleType = () => {
  const t = useTranslate(locales);
  return {
    Header: t('name'),
    accessor: 'name',
    width: 200,
  };
};
